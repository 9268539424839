import React from 'react'
import { SvgIcon, SvgIconProps } from '@hermes/web-components'

const TickIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12.5" r="8.18516" stroke="#4C5870" strokeWidth="1.2" fill="none" />
    <path
      d="M8.43164 13.3922L10.1372 15.0978C10.3325 15.293 10.649 15.293 10.8443 15.0978L15.5695 10.3726"
      stroke="#4C5870"
      strokeWidth="1.2"
      fill="none"
      strokeLinecap="round"
    />
  </SvgIcon>
)

export default TickIcon
