import getConfigVariable, { getServerConfigVariable } from '../utils/getConfigVariable'
import apiRequest from './request'

const basePath = getConfigVariable('TRANSLATIONS_API_URL')

export const getLocaleTranslations = (lang?: string): Promise<Record<string, string>> => {
  const apiKey = getServerConfigVariable('TRANSLATIONS_API_KEY')
  const projectId = getServerConfigVariable('TRANSLATIONS_PROJECT_ID')
  const queryParams = new URLSearchParams()
  queryParams.append('apiKey', apiKey)
  queryParams.append('projectId', projectId)
  queryParams.append('languageCode', lang || 'en')
  return apiRequest(`${basePath}?${queryParams}`)
    .then((r) => r.json())
    .then((r) =>
      r.terms.reduce(
        (prev: Record<string, string>, curr: { term: string; translation: { content: string } }) => ({
          ...prev,
          [curr.term]: curr.translation.content
        }),
        {}
      )
    )
}
