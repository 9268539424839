import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

type MenuProps = SvgIconProps & {
  pathFill?: string
}

const Menu = ({ pathFill, ...props }: MenuProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill={pathFill || '#2B59E0'} />
  </SvgIcon>
)

export default Menu
