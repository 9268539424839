import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function Accordion(): Components['MuiAccordion'] {
  return {
    styleOverrides: {
      root: {
        marginBottom: '2px',
        boxShadow: 'none',
        '&.Mui-expanded': {
          margin: 0,
          marginBottom: '2px'
        },
        '&:before': {
          display: 'none'
        }
      }
    }
  }
}
