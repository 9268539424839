import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function Tabs(theme: Theme): Components['MuiTabs'] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'lowercase'
      }
    }
  }
}
