const palette = {
  common: {
    white: '#FFF'
  },
  text: {
    primary: '#2C3341', // text.black1000
    secondary: '#4C5870', // text.black800
    dark: '#112971',
    disabled: '#5C6B88',
    light: '#FFFFFFCC',
    darkNavy: '#051851',
    darkNavy800: 'rgba(5, 24, 81, 0.8)',
    darkNavy900: '#1A3586',
    black200: '#CFD4DF',
    black700: '#5C6B88',
    black900: '#3C4659'
  },
  primary: {
    main: '#2B59E0',
    dark: '#112971',
    light: '#00A3FF'
  },
  secondary: {
    main: '#009DE4',
    light: '#EAEEFC',
    dark: '#034D8C'
  },
  grey: {
    100: '#E7EAEF',
    200: '#CFD4DF',
    300: '#B7BFCE',
    400: '#9FAABE',
    500: '#80858D',
    600: '#D5DEF9',
    800: '#4C5870'
  },
  blue: {
    200: '#D5DEF9',
    300: '#BFCDF6',
    400: '#AABDF3',
    500: '#95ACF0',
    700: '#6B8BE9',
    800: '#557AE6',
    900: '#406AE3'
  },
  background: {
    default: '#F4F7FD',
    light: '#EDF2F5',
    light2: '#F4F6F6',
    dark: 'rgba(255,255,255,0.22)',
    dark2: '#09122e'
  },
  success: {
    main: 'rgba(0, 133, 53, 0.9)'
  },
  divider: '#CFD4DF',
  system: {
    // green
    green: '#008579',
    greenDark: '#008535',
    // aqua
    aqua: '#00E5D0',
    aqua200: 'rgba(0, 229, 208, 0.2)',
    aqua400: 'rgba(0, 229, 208, 0.4)',
    aqua600: 'rgba(0, 229, 208, 0.6)',
    aqua800: 'rgba(0, 229, 208, 0.8)',
    aqua900: 'rgba(10, 193, 177, 0.80)',
    // coral
    coral: '#FF635C',
    coral200: 'rgba(255, 99, 92, 0.2)',
    coral400: 'rgba(255, 99, 92, 0.4)',
    coral800: 'rgba(255, 99, 92, 0.8)',
    coral900: '#E04F49',
    // lightBlue
    lightBlue: '#00A3FF',
    lightBlue100: 'rgba(85, 122, 230, 0.3)',
    lightBlue200: 'rgba(0, 163, 255, 0.2)',
    lightBlue400: 'rgba(0, 163, 255, 0.4)',
    lightBlue600: 'rgba(0, 163, 255, 0.6)',
    lightBlue800: 'rgba(0, 163, 255, 0.8)',
    white200: 'rgba(255, 255, 255, 0.2)',
    white400: 'rgba(255, 255, 255, 0.4)',
    navy: '#112971',
    navy800: 'rgba(17, 41, 113, 0.8)',
    navy900: '#11245A',
    blue: '#2B59E0',
    hoverBlue: '#3A60CF'
  }
}

export default palette
