import { theme, createTheme } from '@hermes/web-components'
import {
  fontFamily,
  fontFamilyESKlarheitKurrent,
  PoppinsSemiBold,
  PoppinsRegular,
  ESKlarheitKurrentBold,
  ESKlarheitKurrentRegular
} from './fonts'

const getTheme = (isRtl = false) =>
  createTheme({
    ...theme,
    direction: isRtl ? 'rtl' : undefined,
    breakpoints: {
      values: {
        xl: 1440,
        lg: 1056,
        md: 992,
        sm: 610,
        xs: 0
      }
    },
    typography: {
      ...theme.typography,
      htmlFontSize: 15,
      fontFamily,
      h1: {
        fontSize: '61.65px',
        fontWeight: 900,
        fontFamily: fontFamilyESKlarheitKurrent,
        [theme.breakpoints.down('lg')]: {
          fontSize: '50px'
        }
      },
      h2: {
        fontSize: '27.03px',
        fontWeight: 200,
        fontFamily: fontFamilyESKlarheitKurrent
      },
      h3: {
        fontSize: '21.36px',
        fontWeight: 200,
        fontFamily: fontFamilyESKlarheitKurrent
      },
      h4: {
        fontSize: '16.88px',
        fontWeight: 500,
        fontFamily
      },
      body1: {
        fontSize: '14px',
        fontFamily
      },
      body2: {
        fontSize: '13.33px',
        fontFamily
      },
      caption: {
        fontSize: '11.85px',
        fontFamily
      },
      subtitle2: {
        fontSize: '10.53px',
        fontFamily
      }
    },
    components: {
      ...theme.components,
      MuiCssBaseline: {
        styleOverrides: `
        @font-face ${ESKlarheitKurrentBold}
        @font-face ${ESKlarheitKurrentRegular}
        @font-face ${PoppinsSemiBold}
        @font-face ${PoppinsRegular}
        body {
          overflow-x: hidden;
        }
      `
      },
      MuiButtonBase: {
        ...theme.components?.MuiButtonBase,
        styleOverrides: {
          root: {
            fontFamily: `${fontFamily}`,
            fontSize: '15px'
          }
        },
        defaultProps: {
          disableRipple: true
        }
      }
    }
  })

export default getTheme
