import type { Theme } from '@mui/material/styles/createTheme'
import type { Components } from '@mui/material/styles/components'
import {
  Avatar,
  Button,
  IconButton,
  Chip,
  AvatarGroup,
  AppBar,
  Badge,
  LinearProgress,
  Tabs,
  Tab,
  TextField,
  Select,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
  Paper,
  OutlinedInput,
  ButtonBase,
  MenuItem,
  List,
  PaginationItem
} from './components'

export default function getOverrides(theme: Theme): Components {
  return {
    MuiRating: Rating(),
    MuiAccordion: Accordion(),
    MuiAccordionSummary: AccordionSummary(theme),
    MuiAccordionDetails: AccordionDetails(),
    MuiAppBar: AppBar(),
    MuiAvatar: Avatar(),
    MuiButton: Button(theme),
    MuiIconButton: IconButton(theme),
    MuiButtonBase: ButtonBase(theme),
    MuiChip: Chip(theme),
    MuiAvatarGroup: AvatarGroup(),
    MuiBadge: Badge(),
    MuiLinearProgress: LinearProgress(theme),
    MuiTabs: Tabs(theme),
    MuiTab: Tab(theme),
    MuiTextField: TextField(theme),
    MuiSelect: Select(theme),
    MuiInputBase: InputBase(theme),
    MuiPaper: Paper(),
    MuiOutlinedInput: OutlinedInput(theme),
    MuiMenuItem: MenuItem(theme),
    MuiList: List(),
    MuiPaginationItem: PaginationItem(theme)
  }
}
