import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const EmptyStarIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" viewBox="0 0 18 17" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46757 14.6305C9.17485 14.4803 8.82515 14.4803 8.53243 14.6305L4.52693 16.6852C3.78979 17.0633 2.92826 16.4526 3.06904 15.6517L3.83402 11.2996C3.88992 10.9816 3.78186 10.6571 3.54505 10.4318L0.304532 7.34971C-0.291827 6.7825 0.0372524 5.79426 0.8614 5.67741L5.33968 5.04246C5.66695 4.99606 5.94986 4.79549 6.09622 4.50613L8.09897 0.546521C8.46754 -0.182175 9.53246 -0.182173 9.90103 0.546522L11.9038 4.50613C12.0501 4.79549 12.3331 4.99606 12.6603 5.04246L17.1386 5.67741C17.9627 5.79426 18.2918 6.7825 17.6955 7.34971L14.455 10.4318C14.2181 10.6571 14.1101 10.9816 14.166 11.2996L14.931 15.6517C15.0717 16.4526 14.2102 17.0633 13.4731 16.6852L9.46757 14.6305Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.2347 16.1428L8.2402 14.0881C8.71587 13.8441 9.28413 13.8441 9.7598 14.0881L13.7653 16.1428C14.0417 16.2846 14.3648 16.0556 14.312 15.7553L13.547 11.4032C13.4562 10.8864 13.6318 10.3591 14.0166 9.99304L17.2571 6.91092C17.4808 6.69822 17.3573 6.32763 17.0483 6.28381L12.57 5.64886C12.0382 5.57345 11.5785 5.24753 11.3406 4.77732L9.33789 0.817711C9.19967 0.544448 8.80033 0.54445 8.66211 0.817709L6.65937 4.77732C6.42153 5.24753 5.9618 5.57345 5.42998 5.64886L0.951705 6.28381C0.642648 6.32763 0.519245 6.69822 0.742878 6.91092L3.98339 9.99304C4.36822 10.3591 4.54382 10.8864 4.45298 11.4032L3.68799 15.7552C3.6352 16.0556 3.95828 16.2846 4.2347 16.1428ZM8.53243 14.6305C8.82515 14.4803 9.17485 14.4803 9.46757 14.6305L13.4731 16.6852C14.2102 17.0633 15.0717 16.4526 14.931 15.6517L14.166 11.2996C14.1101 10.9816 14.2181 10.6571 14.455 10.4318L17.6955 7.34971C18.2918 6.7825 17.9627 5.79426 17.1386 5.67741L12.6603 5.04246C12.3331 4.99606 12.0501 4.79549 11.9038 4.50613L9.90103 0.546522C9.53246 -0.182173 8.46754 -0.182174 8.09897 0.546521L6.09622 4.50613C5.94986 4.79549 5.66695 4.99606 5.33968 5.04246L0.8614 5.67741C0.0372524 5.79426 -0.291827 6.7825 0.304532 7.34971L3.54505 10.4318C3.78186 10.6571 3.88992 10.9816 3.83402 11.2996L3.06904 15.6517C2.92826 16.4526 3.78979 17.0633 4.52693 16.6852L8.53243 14.6305Z"
      fill="#008579"
    />
  </SvgIcon>
)

export default EmptyStarIcon
