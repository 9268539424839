import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function AccordionSummary(theme: Theme): Components['MuiAccordionSummary'] {
  return {
    styleOverrides: {
      root: {
        minHeight: '36px',
        height: '36px',
        fontSize: '13.33px',
        color: theme.palette.text.dark,
        backgroundColor: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        '&.Mui-expanded': {
          height: '36px',
          minHeight: '36px'
        }
      },
      content: {
        margin: '8px 0'
      }
    }
  }
}
