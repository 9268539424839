import { styledComponent, Box, Link } from '@hermes/web-components'

export const StyledBox = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  color: theme.palette.secondary.light
}))

export const StyledInfoBox = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark2,
  color: theme.palette.secondary.light
}))

export const StyledLink = styledComponent(Link)(({ theme }) => ({
  ...theme.typography.body2,
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    color: theme.palette.system.aqua
  }
}))
