import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const StarIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 23" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6234 19.5073C12.2331 19.3071 11.7669 19.3071 11.3766 19.5073L6.03591 22.2469C5.05306 22.7511 3.90434 21.9368 4.09205 20.8689L5.11203 15.0662C5.18657 14.6421 5.04248 14.2094 4.72673 13.9091L0.406043 9.79962C-0.389103 9.04333 0.0496698 7.72568 1.14853 7.56988L7.11957 6.72328C7.55593 6.66141 7.93315 6.39399 8.1283 6.00817L10.7986 0.728695C11.2901 -0.242899 12.7099 -0.242898 13.2014 0.728696L15.8717 6.00817C16.0668 6.39399 16.4441 6.66141 16.8804 6.72328L22.8515 7.56988C23.9503 7.72568 24.3891 9.04334 23.594 9.79962L19.2733 13.9091C18.9575 14.2094 18.8134 14.6421 18.888 15.0662L19.9079 20.8689C20.0957 21.9368 18.9469 22.7511 17.9641 22.2469L12.6234 19.5073Z"
      fill="#00E5D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6234 19.5073C12.2331 19.3071 11.7669 19.3071 11.3766 19.5073L6.03591 22.2469C5.05306 22.7511 3.90434 21.9368 4.09205 20.8689L5.11203 15.0662C5.18657 14.6421 5.04248 14.2094 4.72673 13.9091L0.406043 9.79962C-0.389103 9.04333 0.0496698 7.72568 1.14853 7.56988L7.11957 6.72328C7.55593 6.66141 7.93315 6.39399 8.1283 6.00817L10.7986 0.728695C11.2901 -0.242899 12.7099 -0.242898 13.2014 0.728696L15.8717 6.00817C16.0668 6.39399 16.4441 6.66141 16.8804 6.72328L22.8515 7.56988C23.9503 7.72568 24.3891 9.04334 23.594 9.79962L19.2733 13.9091C18.9575 14.2094 18.8134 14.6421 18.888 15.0662L19.9079 20.8689C20.0957 21.9368 18.9469 22.7511 17.9641 22.2469L12.6234 19.5073Z"
      fill="#00E5D0"
      fillOpacity="0.35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64627 21.5238L10.9869 18.7841C11.6212 18.4588 12.3788 18.4588 13.0131 18.7841L18.3537 21.5238C18.7223 21.7128 19.1531 21.4075 19.0827 21.007L18.0627 15.2043C17.9416 14.5152 18.1757 13.8121 18.6888 13.3241L23.0095 9.21456C23.3077 8.93095 23.1431 8.43684 22.7311 8.37841L16.76 7.53181C16.0509 7.43127 15.438 6.99671 15.1208 6.36975L12.4505 1.09028C12.2662 0.725931 11.7338 0.725934 11.5495 1.09028L8.87916 6.36975C8.56204 6.99671 7.94906 7.43127 7.23998 7.53181L1.26894 8.37841C0.856863 8.43684 0.692327 8.93096 0.990505 9.21456L5.31119 13.3241C5.82429 13.8121 6.05843 14.5152 5.9373 15.2043L4.91732 21.007C4.84693 21.4075 5.2777 21.7128 5.64627 21.5238ZM11.3766 19.5073C11.7669 19.3071 12.2331 19.3071 12.6234 19.5073L17.9641 22.2469C18.9469 22.7511 20.0957 21.9368 19.9079 20.8689L18.888 15.0662C18.8134 14.6421 18.9575 14.2094 19.2733 13.9091L23.594 9.79962C24.3891 9.04334 23.9503 7.72568 22.8515 7.56988L16.8804 6.72328C16.4441 6.66141 16.0668 6.39399 15.8717 6.00817L13.2014 0.728696C12.7099 -0.242898 11.2901 -0.242899 10.7986 0.728694L8.1283 6.00817C7.93315 6.39399 7.55593 6.66141 7.11957 6.72328L1.14853 7.56988C0.0496698 7.72568 -0.389103 9.04333 0.406043 9.79962L4.72673 13.9091C5.04248 14.2094 5.18657 14.6421 5.11203 15.0662L4.09205 20.8689C3.90434 21.9368 5.05306 22.7511 6.03591 22.2469L11.3766 19.5073Z"
      fill="#008579"
      fillOpacity="0.6"
    />
  </SvgIcon>
)

export default StarIcon
