import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function OutlinedInput(theme: Theme): Components['MuiOutlinedInput'] {
  return {
    styleOverrides: {
      root: {
        outline: 'none',
        borderWidth: 1,
        '&:hover .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline, &:focus-visible .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.palette.primary.main,
            outline: 'none',
            borderWidth: 1
          },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1
        },
        '& .Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 1
          }
        }
      },
      notchedOutline: {
        borderWidth: 1,
        outline: 'none'
      }
    }
  }
}
