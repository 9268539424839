import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function InputBase(theme: Theme): Components['MuiInputBase'] {
  return {
    styleOverrides: {
      root: {
        fontSize: '13.33px',
        fontFamily: theme.typography.fontFamily,
        '&:before': {
          display: 'none'
        },
        '& > input::placeholder': {
          color: theme.palette.text.disabled,
          opacity: 1,
          fontSize: '13.33px',
          fontWeight: 400
        },
        '&:hover, &:active, &:focus': {
          borderColor: theme.palette.primary.main,
          outline: 'none'
        }
      }
    }
  }
}
