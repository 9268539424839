export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const getProviderUrl = (tenant: string) => {
  if (tenant === 'uk' || tenant === 'ie') {
    return '/provider/join-doctify/'
  }

  if (tenant === 'de' || tenant === 'de-at') {
    return '/about/business-center/'
  }

  // au, en-ae, ar-ae, test
  return '/about/join-doctify/'
}
