export function getCookie(cookie: string, cookieName: string) {
  if (!cookie) {
    return ''
  }
  return cookie
    .split(';')
    .filter((item) => item.includes(`${cookieName}=`))[0]
    ?.replace(`${cookieName}=`, '')
    .trim()
}

type HandleAddCookieCTAPropType = {
  id: number
  slug: string
  type: string
}

export const handleAddCookieCTA = ({ id, slug, type }: HandleAddCookieCTAPropType) => {
  const cookies = [
    { name: 'uuid_of_referring_unclaimed_profile', value: id },
    { name: 'slug_of_referring_unclaimed_profile', value: slug },
    { name: 'type_of_referring_unclaimed_profile', value: type }
  ]

  cookies.forEach((cookie) => {
    document.cookie = `${cookie.name}=${encodeURIComponent(cookie.value)}; path=/; max-age=${
      60 * 60 * 24 * 365
    }; domain=.doctify.com; Secure; SameSite=None`
  })
}
