import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function PaginationItem(theme: Theme): Components['MuiPaginationItem'] {
  return {
    styleOverrides: {
      rounded: {
        fontSize: '12px',
        borderRadius: '2px',
        height: '28px',
        color: theme.palette.primary.main,
        '&.MuiPaginationItem-previousNext': {
          border: `1px solid ${theme.palette.system.blue}`,
          opacity: 1,
          '&.Mui-disabled': {
            borderColor: theme.palette.grey[500],
            '.MuiSvgIcon-root': {
              fill: theme.palette.grey[500]
            }
          }
        },
        '&.MuiPaginationItem-ellipsis': {
          height: 'auto'
        }
      },
      root: {
        '& .Mui-selected': {
          color: theme.palette.common.white
        }
      }
    }
  }
}
