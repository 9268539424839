const localeMapping: Record<string, string> = {
  uk: 'en',
  de: 'de',
  'de-at': 'de',
  au: 'en',
  'en-ae': 'en',
  'ar-ae': 'ar'
}

export default function getLanguageFromLocale(locale: string) {
  return localeMapping[locale] ?? 'en'
}
