import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function AccordionDetails(): Components['MuiAccordionDetails'] {
  return {
    styleOverrides: {
      root: {
        padding: '2px 32px'
      }
    }
  }
}
