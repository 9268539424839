const basePath = '/hermes-static/fonts/'

const ESKlarheitKurrentBoldWoff2 = `${basePath}ESKlarheitKurrent/woff2/ESKlarheitKurrent-Bold.woff2`
const ESKlarheitKurrentLightWoff2 = `${basePath}ESKlarheitKurrent/woff2/ESKlarheitKurrent-Extralight.woff2`
const ESKlarheitKurrentMediumWoff2 = `${basePath}ESKlarheitKurrent/woff2/ESKlarheitKurrent-Medium.woff2`
const ESKlarheitKurrentRegularWoff2 = `${basePath}ESKlarheitKurrent/woff2/ESKlarheitKurrent-Regular.woff2`

const ESKlarheitKurrentBoldWoff = `${basePath}ESKlarheitKurrent/woff/ESKlarheitKurrent-Bold.woff`
const ESKlarheitKurrentLightWoff = `${basePath}ESKlarheitKurrent/woff/ESKlarheitKurrent-Extralight.woff`
const ESKlarheitKurrentMediumWoff = `${basePath}ESKlarheitKurrent/woff/ESKlarheitKurrent-Medium.woff`
const ESKlarheitKurrentRegularWoff = `${basePath}ESKlarheitKurrent/woff/ESKlarheitKurrent-Regular.woff`

const PoppinsBoldWoff2 = `${basePath}Poppins/woff2/Poppins-Bold.woff2`
const PoppinsSemiBoldWoff2 = `${basePath}Poppins/woff2/Poppins-SemiBold.woff2`
const PoppinsLightWoff2 = `${basePath}Poppins/woff2/Poppins-ExtraLight.woff2`
const PoppinsMediumWoff2 = `${basePath}Poppins/woff2/Poppins-Medium.woff2`
const PoppinsRegularWoff2 = `${basePath}Poppins/woff2/Poppins-Regular.woff2`

const PoppinsBoldWoff = `${basePath}Poppins/woff/Poppins-Bold.woff`
const PoppinsSemiBoldWoff = `${basePath}Poppins/woff/Poppins-SemiBold.woff`
const PoppinsLightWoff = `${basePath}Poppins/woff/Poppins-ExtraLight.woff`
const PoppinsMediumWoff = `${basePath}Poppins/woff/Poppins-Medium.woff`
const PoppinsRegularWoff = `${basePath}Poppins/woff/Poppins-Regular.woff`

export const ESKlarheitKurrentBold = `
  {
    font-family: 'ES Klarheit Kurrent';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(${ESKlarheitKurrentBoldWoff2}) format('woff2'),
        url(${ESKlarheitKurrentBoldWoff}) format('woff');
  }
`

export const ESKlarheitKurrentLight = `
  {
    font-family: ES Klarheit Kurrent;
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: url(${ESKlarheitKurrentLightWoff2}) format('woff2'),
      url(${ESKlarheitKurrentLightWoff}) format('woff');
  }
`

export const ESKlarheitKurrentMedium = `
  {
    font-family: 'ES Klarheit Kurrent';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url(${ESKlarheitKurrentMediumWoff2}) format('woff2'),
      url(${ESKlarheitKurrentMediumWoff}) format('woff');
  }
`

export const ESKlarheitKurrentRegular = `
  {
    font-family: 'ES Klarheit Kurrent';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(${ESKlarheitKurrentRegularWoff2}) format('woff2'),
    url(${ESKlarheitKurrentRegularWoff}) format('woff');
  }
`

export const PoppinsBold = `
  {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(${PoppinsBoldWoff2}) format('woff2'),
    url(${PoppinsBoldWoff}) format('woff');
  }
`

export const PoppinsSemiBold = `
  {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url(${PoppinsSemiBoldWoff2}) format('woff2'),
    url(${PoppinsSemiBoldWoff}) format('woff');
  }
`

export const PoppinsLight = `
  {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: url(${PoppinsLightWoff2}) format('woff2'),
    url(${PoppinsLightWoff}) format('woff');
  }
`

export const PoppinsMedium = `
  {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(${PoppinsMediumWoff2}) format('woff2'),
    url(${PoppinsMediumWoff}) format('woff');
  }
`

export const PoppinsRegular = `
  {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(${PoppinsRegularWoff2}) format('woff2'),
    url(${PoppinsRegularWoff}) format('woff');
  }
`

const fallbackFontFamily = ['sans-serif']

export const fontFamily = ['Poppins', ...fallbackFontFamily].join(',')
export const fontFamilyESKlarheitKurrent = ['ES Klarheit Kurrent', ...fallbackFontFamily].join(',')

const fontExportData = {
  fontFamily,
  fontFamilyESKlarheitKurrent,
  PoppinsBold,
  PoppinsSemiBold,
  PoppinsLight,
  PoppinsMedium,
  PoppinsRegular,
  ESKlarheitKurrentBold,
  ESKlarheitKurrentLight,
  ESKlarheitKurrentMedium,
  ESKlarheitKurrentRegular
}

export default fontExportData
