import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function MenuItem(theme: Theme): Components['MuiMenuItem'] {
  return {
    styleOverrides: {
      root: {
        minWidth: 200,
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13.33px',
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(1.75, 2),
        '&:hover': {
          backgroundColor: theme.palette.background.light
        },
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default
        }
      }
    }
  }
}
