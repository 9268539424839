import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function ButtonBase(theme: Theme): Components['MuiButtonBase'] {
  return {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: theme.typography.htmlFontSize,
        lineHeight: '24px',
        fontFamily: theme.typography.fontFamily,
        textShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)'
      }
    }
  }
}
