import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const LocationOn = ({ color, ...props }: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 8C7.175 8 6.5 7.37 6.5 6.6C6.5 5.83 7.175 5.2 8 5.2C8.825 5.2 9.5 5.83 9.5 6.6C9.5 7.37 8.825 8 8 8ZM12.5 6.74C12.5 4.199 10.5125 2.4 8 2.4C5.4875 2.4 3.5 4.199 3.5 6.74C3.5 8.378 4.9625 10.548 8 13.138C11.0375 10.548 12.5 8.378 12.5 6.74ZM8 1C11.15 1 14 3.254 14 6.74C14 9.064 11.9975 11.815 8 15C4.0025 11.815 2 9.064 2 6.74C2 3.254 4.85 1 8 1Z"
      fill={color || '#5C6B88'}
    />
  </SvgIcon>
)

export default LocationOn
