import { Grid, styledComponent } from '@hermes/web-components'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type GridHiddenProps = {
  up?: Breakpoint
  not?: Breakpoint
  between?: [Breakpoint, Breakpoint]
  only?: Breakpoint
  down?: Breakpoint
}

export const GridHidden = styledComponent(Grid, {
  shouldForwardProp: (prop: string) => !['up', 'down', 'not', 'between', 'only'].includes(prop)
})<GridHiddenProps>(({ theme, up, down, not, between, only }) => ({
  ...(up
    ? {
        [theme.breakpoints.up(up)]: {
          display: 'none'
        }
      }
    : {}),
  ...(down
    ? {
        [theme.breakpoints.down(down)]: {
          display: 'none'
        }
      }
    : {}),
  ...(only
    ? {
        [theme.breakpoints.only(only)]: {
          display: 'none'
        }
      }
    : {}),
  ...(between?.length === 2
    ? {
        [theme.breakpoints.between(between[0], between[1])]: {
          display: 'none'
        }
      }
    : {}),
  ...(not
    ? {
        [theme.breakpoints.not(not)]: {
          display: 'none'
        }
      }
    : {})
}))
