export const TENANTS = {
  UK: 'uk',
  DE: 'de',
  AT: 'de-at',
  AU: 'au',
  EN_UAE: 'en-ae',
  AR_UAE: 'ar-ae'
}

export const LANGUAGES = {
  EN: 'en',
  DE: 'de',
  AR: 'ar'
}

export const GTM_ID = 'GTM-K73CL79'

export const RTL_TENANTS = [TENANTS.AR_UAE]
export const RIGHT_TO_LEFT = 'rtl'
export const LEFT_TO_RIGHT = 'ltr'
export const MUI_RTL = 'muirtl'
export const MUI_LTR = 'muiltr'
export const DEFAULT_LOCALE = 'en'
export const PATH_TO_UNAVAILABLE_IMG = '/hermes-static/images/video-unavailable.webp'

export const remapDaysOfWeekIndex = [7, 1, 2, 3, 4, 5, 6] // Where: 7 - Sunday, 1 - Monday, ..., 6 - Saturday
