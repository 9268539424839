import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function AvatarGroup(): Components['MuiAvatarGroup'] {
  return {
    defaultProps: {
      variant: 'square'
    },
    styleOverrides: {
      avatar: {
        root: {
          backgroundColor: 'lightgray'
        },
        square: {
          borderRadius: 2
        }
      }
    }
  }
}
