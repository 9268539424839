import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function Tab(theme: Theme): Components['MuiTab'] {
  return {
    styleOverrides: {
      root: {
        color: theme.palette.common.white,
        textTransform: 'none',
        padding: theme.spacing(1.25, 1.5),
        position: 'relative',
        '&.Mui-selected': {
          color: '#B4FFFB',
          '&:after': {
            // eslint-disable-next-line quotes
            content: "''",
            display: 'block',
            position: 'absolute',
            bottom: 0,
            background:
              'linear-gradient(90deg, rgba(0, 163, 255, 0.7) 0%, rgba(0, 229, 208, 0.7) 99.99%, rgba(0, 229, 208, 0.7) 100%), #00A3FF',
            width: '100%',
            borderRadius: 4,
            height: 4,
            left: 0
          }
        },
        '& .MuiTouchRipple-root': {
          display: 'none'
        }
      }
    }
  }
}
