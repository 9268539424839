export default function getTenantName(locale: string) {
  switch (locale) {
    case 'uk': {
      return 'athena-uk'
    }
    case 'de': {
      return 'athena-de'
    }
    case 'au': {
      return 'athena-au'
    }
    case 'de-at': {
      return 'athena-at'
    }
    case 'en-ae':
    case 'ar-ae': {
      return 'athena-uae'
    }
    case 'ie': {
      return 'athena-ie'
    }
    default: {
      return 'athena-uk'
    }
  }
}
