import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const PersonWithShieldIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M6.38075 6.28585C7.3867 6.28585 8.20218 5.4224 8.20218 4.35728C8.20218 3.29216 7.3867 2.42871 6.38075 2.42871C5.37481 2.42871 4.55933 3.29216 4.55933 4.35728C4.55933 5.4224 5.37481 6.28585 6.38075 6.28585Z"
      stroke="#4C5870"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.19019 8.1659C6.65639 8.03541 6.10163 8.03438 5.56739 8.16287C5.03316 8.29137 4.53326 8.54607 4.10509 8.90794C3.67691 9.2698 3.33153 9.72947 3.09476 10.2526C2.858 10.7757 2.73597 11.3487 2.73781 11.9288V13.2145H7.19019"
      stroke="#4C5870"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.2831 14.0459C11.1942 14.0799 11.0968 14.0799 11.0078 14.0459C10.3327 13.7627 9.75343 13.2714 9.34497 12.6357C8.93652 12 8.71772 11.2491 8.71689 10.4802V9.35731C8.71332 9.30005 8.72133 9.24264 8.74041 9.18892C8.75949 9.13521 8.78918 9.08643 8.8275 9.04585C8.86583 9.00527 8.9119 8.97383 8.96263 8.95363C9.01336 8.93344 9.06758 8.92495 9.12166 8.92874H13.1693C13.2234 8.92495 13.2776 8.93344 13.3283 8.95363C13.379 8.97383 13.4251 9.00527 13.4634 9.04585C13.5018 9.08643 13.5314 9.13521 13.5505 9.18892C13.5696 9.24264 13.5776 9.30005 13.574 9.35731V10.4802C13.5732 11.2491 13.3544 12 12.946 12.6357C12.5375 13.2714 11.9582 13.7627 11.2831 14.0459Z"
      stroke="#4C5870"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)

export default PersonWithShieldIcon
