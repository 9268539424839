import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function Avatar(): Components['MuiAvatar'] {
  return {
    defaultProps: {
      variant: 'square'
    },
    styleOverrides: {
      root: {},
      square: {
        borderRadius: 2
      }
    }
  }
}
