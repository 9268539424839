const apiRequest = async (url: string, options: RequestInit = {}) =>
  fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Source: 'hermes-web'
    }
  })

export default apiRequest
