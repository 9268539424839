import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function Rating(): Components['MuiRating'] {
  return {
    styleOverrides: {
      icon: {
        margin: '0 2px'
      }
    }
  }
}
