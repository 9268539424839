import React, { PropsWithChildren, useContext } from 'react'
import { AppDataContext } from '../providers/AppData'
import Header from './Header'
import Footer from './Footer'

function App({ children }: PropsWithChildren<object>) {
  const { tenantSettings, locale, language } = useContext(AppDataContext)

  return (
    <>
      <Header />
      {children}
      <Footer
        locale={locale}
        lang={language}
        footerColumnLinks={tenantSettings?.footerColumnLinks}
        footerSocialLinks={tenantSettings?.footerSocialLinks}
        contactPhoneNumber={tenantSettings?.contactPhoneNumber || ''}
      />
    </>
  )
}

export default App
