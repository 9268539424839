import type { Components } from '@mui/material/styles/components'
// import { Theme } from '@mui/material/styles/createTheme'

export default function Badge(): Components['MuiBadge'] {
  return {
    styleOverrides: {
      badge: {
        backgroundColor: 'red'
      }
    }
  }
}
