import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function LinearProgress(theme: Theme): Components['MuiLinearProgress'] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: '2px',
        height: '8px'
      },
      bar: {
        background: theme.palette.secondary.main,
        borderRadius: '2px',
        height: '8px'
      }
    }
  }
}
