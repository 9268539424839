import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CheckboxCircleOutlineIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 17" width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8" cy="8.5" r="6.35" stroke="#4C5870" strokeWidth="1.3" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6003 5.88411C11.8542 6.13795 11.8542 6.5495 11.6003 6.80334L7.28784 11.1158C7.034 11.3697 6.62244 11.3697 6.3686 11.1158L4.39985 9.14709C4.14601 8.89325 4.14601 8.4817 4.39985 8.22786C4.65369 7.97402 5.06525 7.97402 5.31909 8.22786L6.82822 9.73699L10.6811 5.88411C10.9349 5.63027 11.3465 5.63027 11.6003 5.88411Z"
      fill="#4C5870"
    />
  </SvgIcon>
)

export default CheckboxCircleOutlineIcon
