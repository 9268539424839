import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function Select(theme: Theme): Components['MuiSelect'] {
  return {
    defaultProps: {
      variant: 'standard',
      IconComponent: ExpandMoreIcon
    },
    styleOverrides: {
      // @ts-ignore
      root: {
        '&:before, &:after': {
          display: 'none'
        }
      },
      standard: {
        color: theme.palette.primary.dark,
        fontSize: theme.typography.body2.fontSize,
        border: 'none',
        '&:before, &:after': {
          display: 'none'
        }
      },
      icon: {
        fontSize: '1.2rem',
        color: 'inherit',
        height: '1rem',
        widht: '1rem'
      }
    }
  }
}
