import getConfig from 'next/config'

type ConfigKey =
  | 'HOST'
  | 'BASIC_API_URL'
  | 'googleMapsKey'
  | 'POEDITOR_API_URL'
  | 'POEDITOR_ACCESS_KEY'
  | 'POEDITOR_PROJECT_ID'
  | 'STAGE'
  | 'TRANSLATIONS_API_URL'
  | 'TRANSLATIONS_API_KEY'
  | 'TRANSLATIONS_PROJECT_ID'
  | 'INTERNAL_API_URL'

export default function getConfigVariable(key: ConfigKey) {
  const { publicRuntimeConfig } = getConfig()

  return publicRuntimeConfig[key] || ''
}

export const getServerConfigVariable = (key: ConfigKey): string => {
  const { serverRuntimeConfig } = getConfig()

  return serverRuntimeConfig[key] || ''
}
