import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material/styles/createTheme'

export default function Chip(theme: Theme): Components['MuiChip'] {
  return {
    defaultProps: {
      size: 'small'
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: 16,
        background: theme.palette.grey[100],
        padding: theme.spacing(0.25, 0.5),
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled
      },
      outlined: {
        backgroundColor: 'transparent'
      }
    },
    variants: [
      {
        props: { variant: 'option' },
        style: {
          fontSize: theme.typography?.body2?.fontSize,
          fontFamily: theme.typography.fontFamily,
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.main,
          border: '1px solid transparent',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            // Safari does not have full support for `outline` https://webkit.org/b/20807
            // outline: `1px ${theme.palette.primary.main} solid`
            border: `1px ${theme.palette.primary.main} solid`
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      },
      {
        props: { variant: 'selected' },
        style: {
          fontSize: theme.typography?.body2?.fontSize,
          fontFamily: theme.typography.fontFamily,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          fontWeight: 500,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            // Safari does not have full support for `outline` https://webkit.org/b/20807
            // outline: 0
            border: 0
          }
        }
      },
      {
        props: { variant: 'aqua' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.system.aqua200,
          borderColor: theme.palette.secondary.light,
          '&:hover': {
            backgroundColor: theme.palette.system.aqua400,
            borderColor: theme.palette.system.aqua800
          },
          '&:focus': {
            backgroundColor: theme.palette.system.aqua400,
            borderColor: theme.palette.system.aqua800
          }
        }
      },
      {
        props: { variant: 'coral' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.system.coral200,
          borderColor: theme.palette.secondary.light,
          '&:hover': {
            backgroundColor: theme.palette.system.coral400,
            borderColor: theme.palette.system.coral800
          },
          '&:focus': {
            backgroundColor: theme.palette.system.coral400,
            borderColor: theme.palette.system.coral800
          }
        }
      },
      {
        props: { variant: 'lightBlue' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.system.lightBlue200,
          borderColor: theme.palette.secondary.light,
          '&:hover': {
            backgroundColor: theme.palette.system.lightBlue400,
            borderColor: theme.palette.system.lightBlue800
          },
          '&:focus': {
            backgroundColor: theme.palette.system.lightBlue400,
            borderColor: theme.palette.system.lightBlue800
          }
        }
      },
      {
        props: { variant: 'blue' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.secondary.light,
          borderColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.blue[200],
            borderColor: theme.palette.system.navy800
          },
          '&:focus': {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.text.darkNavy800
          }
        }
      },
      {
        props: { variant: 'blue200' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.blue[200],
          color: theme.palette.text.darkNavy,
          '&:hover': {
            backgroundColor: theme.palette.blue[300]
          }
        }
      },
      {
        props: { variant: 'blue300' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.blue[300],
          border: `1px solid ${theme.palette.secondary.light}`,
          color: theme.palette.text.dark
        }
      },
      {
        props: { variant: 'blue800' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.blue[800],
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.system.hoverBlue
          }
        }
      },
      {
        props: { variant: 'aqua800' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.system.aqua800,
          color: theme.palette.text.darkNavy,
          '&:hover': {
            backgroundColor: theme.palette.system.aqua900
          }
        }
      },
      {
        props: { variant: 'coralPrimary' },
        // @ts-ignore
        style: {
          backgroundColor: theme.palette.system.coral,
          color: theme.palette.text.darkNavy,
          '&:hover': {
            backgroundColor: theme.palette.system.coral900
          }
        }
      }
    ]
  }
}
