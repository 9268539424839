import type { Components } from '@mui/material/styles/components'

export default function MenuMenuList(): Components['MuiList'] {
  return {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  }
}
